/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.dev-panel-events table th,
td {
    max-width: none !important;
}

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.center {
    display: flex;
    justify-content: center;
}

.spacebetween {
    display: flex;
    justify-content: space-between;
}

.aligncenter {
    display: flex;
    align-items: center;
}

.smallImage {
    width: 6rem;
    height: 6rem;

}

.carouselImage {
    width: 5rem;
}

.carouselSingle {
    width: 35%;
}

.smallbtn {
    width: 2.0rem;
    height: 1.5rem;
    padding: 0rem;
    margin: 0rem;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.profile-picture-container {
    height: min-content;
    width: min-content;
}

input::placeholder {
    color: #a2a2a2 !important;
}

textarea::placeholder {
    color: #a2a2a2 !important;
}

.custom ngb-datepicker .ngb-dp-weekday {
    color: rgb(85, 107, 47);
}

.custom ngb-datepicker .ngb-dp-header .ngb-dp-arrow-btn {
    background-color: transparent;
    color: rgb(85, 107, 47);
}

.custom .ngb-dp-navigation-chevron {
    border-width: .25rem .25rem 0 0;
}

.korona-statistics ngb-datepicker .ngb-dp-weekday {
    color: rgba(0, 109, 56, 1);
}

.korona-statistics ngb-datepicker .ngb-dp-header .ngb-dp-arrow-btn {
    background-color: transparent;
    color: rgba(0, 109, 56, 1);
}

.korona-statistics .ngb-dp-navigation-chevron {
    border-width: .25rem .25rem 0 0;
}

:root {
    --header-bg-color-light: #f5f4ff;
    --header-bg-color-dark: #35345a;

    --dashboard-alert-red-bg-color-light: #f8d7da;
    --dashboard-alert-red-text-color-light: #721c24;
    --dashboard-alert-red-border-color-light: #c2888d;
    --dashboard-alert-red-bg-color-dark: #721c24;
    --dashboard-alert-red-text-color-dark: #f8d7da;
    --dashboard-alert-red-border-color-dark: #c2888d;

    --dashboard-alert-blue-bg-color-light: #cce5ff;
    --dashboard-alert-blue-text-color-light: #004085;
    --dashboard-alert-blue-border-color-light: #6f8dad;
    --dashboard-alert-blue-bg-color-dark: #004085;
    --dashboard-alert-blue-text-color-dark: #cce5ff;
    --dashboard-alert-blue-border-color-dark: #6f8dad;

    --dashboard-alert-green-bg-color-light: #d4edda;
    --dashboard-alert-green-text-color-light: #155724;
    --dashboard-alert-green-border-color-light: #82b58d;
    --dashboard-alert-green-bg-color-dark: #155724;
    --dashboard-alert-green-text-color-dark: #d4edda;
    --dashboard-alert-green-border-color-dark: #82b58d;

    --dashboard-alert-yellow-bg-color-light: #fff3cd;
    --dashboard-alert-yellow-text-color-light: #856404;
    --dashboard-alert-yellow-border-color-light: #ccb872;
    --dashboard-alert-yellow-bg-color-dark: #856404;
    --dashboard-alert-yellow-text-color-dark: #fff3cd;
    --dashboard-alert-yellow-border-color-dark: #ccb872;

    --dashboard-alert-purple-bg-color-light: #dcdafc;
    --dashboard-alert-purple-text-color-light: #544bb5;
    --dashboard-alert-purple-border-color-light: #a8a1ff;
    --dashboard-alert-purple-bg-color-dark: #3e378c;
    --dashboard-alert-purple-text-color-dark: #dcdafc;
    --dashboard-alert-purple-border-color-dark: #a8a1ff;
}
  
.dashboard-alert-asd {
    color: var(--dashboard-alert-custom-text-color-light);
}

.dark-only {
    --header-bg-color: var(--header-bg-color-dark);

    --dashboard-alert-red-bg-color: var(--dashboard-alert-red-bg-color-dark);
    --dashboard-alert-red-text-color: var(--dashboard-alert-red-text-color-dark);
    --dashboard-alert-red-border-color: var(--dashboard-alert-red-border-color-dark);

    --dashboard-alert-blue-bg-color: var(--dashboard-alert-blue-bg-color-dark);
    --dashboard-alert-blue-text-color: var(--dashboard-alert-blue-text-color-dark);
    --dashboard-alert-blue-border-color: var(--dashboard-alert-blue-border-color-dark);

    --dashboard-alert-green-bg-color: var(--dashboard-alert-green-bg-color-dark);
    --dashboard-alert-green-text-color: var(--dashboard-alert-green-text-color-dark);
    --dashboard-alert-green-border-color: var(--dashboard-alert-green-border-color-dark);

    --dashboard-alert-yellow-bg-color: var(--dashboard-alert-yellow-bg-color-dark);
    --dashboard-alert-yellow-text-color: var(--dashboard-alert-yellow-text-color-dark);
    --dashboard-alert-yellow-border-color: var(--dashboard-alert-yellow-border-color-dark);

    --dashboard-alert-purple-text-color: var(--dashboard-alert-purple-text-color-dark);
    --dashboard-alert-purple-bg-color: var(--dashboard-alert-purple-bg-color-dark);
    --dashboard-alert-purple-border-color: var(--dashboard-alert-purple-border-color-dark);
}
  
body:not(.dark-only) {
    --header-bg-color: var(--header-bg-color-light);

    --dashboard-alert-red-bg-color: var(--dashboard-alert-red-bg-color-light);
    --dashboard-alert-red-text-color: var(--dashboard-alert-red-text-color-light);
    --dashboard-alert-red-border-color: var(--dashboard-alert-red-border-color-light);

    --dashboard-alert-blue-bg-color: var(--dashboard-alert-blue-bg-color-light);
    --dashboard-alert-blue-text-color: var(--dashboard-alert-blue-text-color-light);
    --dashboard-alert-blue-border-color: var(--dashboard-alert-blue-border-color-light);

    --dashboard-alert-green-bg-color: var(--dashboard-alert-green-bg-color-light);
    --dashboard-alert-green-text-color: var(--dashboard-alert-green-text-color-light);
    --dashboard-alert-green-border-color: var(--dashboard-alert-green-border-color-light);

    --dashboard-alert-yellow-bg-color: var(--dashboard-alert-yellow-bg-color-light);
    --dashboard-alert-yellow-text-color: var(--dashboard-alert-yellow-text-color-light);
    --dashboard-alert-yellow-border-color: var(--dashboard-alert-yellow-border-color-light);

    --dashboard-alert-purple-text-color: var(--dashboard-alert-purple-text-color-light);
    --dashboard-alert-purple-bg-color: var(--dashboard-alert-purple-bg-color-light);
    --dashboard-alert-purple-border-color: var(--dashboard-alert-purple-border-color-light);
}

.dashboard-alert-red {
    background-color: var(--dashboard-alert-red-bg-color);
    color: var(--dashboard-alert-red-text-color);
    border: .05rem solid var(--dashboard-alert-red-border-color);
    border-radius: .3rem;
    padding: .5rem;
}

.dashboard-alert-blue {
    background-color: var(--dashboard-alert-blue-bg-color);
    color: var(--dashboard-alert-blue-text-color);
    border: .05rem solid var(--dashboard-alert-blue-border-color);
    border-radius: .3rem;
    padding: .5rem;
}

.dashboard-alert-green {
    background-color: var(--dashboard-alert-green-bg-color);
    color: var(--dashboard-alert-green-text-color);
    border: .05rem solid var(--dashboard-alert-green-border-color);
    border-radius: .3rem;
    padding: .5rem;
}

.dashboard-alert-yellow {
    background-color: var(--dashboard-alert-yellow-bg-color);
    color: var(--dashboard-alert-yellow-text-color);
    border: .05rem solid var(--dashboard-alert-yellow-border-color);
    border-radius: .3rem;
    padding: .5rem;
}

.dashboard-alert-purple {
    background-color: var(--dashboard-alert-purple-bg-color);
    color: var(--dashboard-alert-purple-text-color);
    border: .05rem solid var(--dashboard-alert-purple-border-color);
    border-radius: .3rem;
    padding: .5rem;
}

